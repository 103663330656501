import { render, staticRenderFns } from "./EditInsuredForm.vue?vue&type=template&id=d12e7f76&scoped=true&"
import script from "./EditInsuredForm.vue?vue&type=script&lang=js&"
export * from "./EditInsuredForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d12e7f76",
  null
  
)

export default component.exports